

















































import { defineComponent, ref, useFetch } from '@nuxtjs/composition-api';
import { useContent } from '~/composables';

export default defineComponent({
  name: "Brands",
  setup() {
    const { loadBlocks } = useContent();
    const brandsList = ref(null);
    useFetch(async () => {
      const blockData = await loadBlocks({ identifiers: ['dealership_brands'] });
      if (blockData) {
        brandsList.value = JSON.parse(blockData?.[0]?.content);
      }
    });
    return {
      brandsList,
    }
  }
});
